
import React from 'react'

export default function Title({ children, className, style }) {
  return (
    <div className={`text-2xl font-normal leading-none mb-4 ${className}`} style={style}>
      {children}
    </div>
  )
}
