
import React from 'react'
import { FiLoader } from 'react-icons/fi'
import Title from './Title'

export default function Loader({ title, progress }) {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-center opacity-75">
      {title && <Title>{title}</Title>}
      <FiLoader className="h-12 w-12 stroke-1 rotating box-border m-6" />
      {progress && <div className="text-xl -mt-4">{Math.round(progress * 100)} %</div>}
    </div>
  )
}
