import React, { useEffect } from 'react'
import granim from 'granim'
// #26e9ff
// #0ae5fe
// #04c8e8
// #01bade

export default function Loader() {
  // useEffect(() => {
  //   new granim({
  //     element: '#canvas',
  //     direction: 'diagonal',
  //     isPausedWhenNotInView: true,
  //     states : {
  //       'default-state': {
  //         gradients: [
  //           [
  //             { color: '#0ae5fe', pos: .1 },
  //             { color: '#04c8e8', pos: .6 },
  //             { color: '#01bade', pos: 1 }
  //           ],
  //           [
  //             { color: '#0ae5fe', pos: .1 },
  //             { color: '#04c8e8', pos: .7 },
  //             { color: '#01bade', pos: 1.2 }
  //           ],
  //           [
  //             { color: '#0ae5fe', pos: .1 },
  //             { color: '#04c8e8', pos: .4 },
  //             { color: '#01bade', pos: 1 }
  //           ],
  //           [
  //             { color: '#0ae5fe', pos: .2 },
  //             { color: '#04c8e8', pos: .6 },
  //             { color: '#01bade', pos: 1 }
  //           ],
  //         ]
  //       }
  //     }
  //   })
  // }, [])
  //  style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1534142499731-a32a99935397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80")', backgroundSize: 'cover' }}
  return (
    // <canvas id="canvas" className="fixed w-screen h-screen top-0 left-0 -z-10" />
    <div className="fixed w-screen h-screen top-0 left-0 -z-10 bg-beige" />
  )
}

